import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from './ResetPassword.module.css';
import Button from '../../../components/Button/Button';
import { useHistory } from 'react-router';
import PasswordService from '../../../services/PasswordService';
import { AxiosResponse } from 'axios';
import ApiErrorModel from '../../../models/ApiErrorModel';
import Input from '../../../components/Input/Input';
import * as VALIDATION from '../../../constants/Validation';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import UserModelChangePassword from '../../../models/UserModelChangePassword';
import { LOGIN } from '../../../constants/Routes';

interface Props {
    onCancel: () => void;
}

interface InputState {
    value: string;
    error: string | undefined;
}

const ResetPassword: FC<Props> = () => {
    const { code } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [changePassModel, setChangePassModel] = useState<UserModelChangePassword | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [password, setPassword] = useState<InputState>({ value: '', error: undefined });
    const [uuid, setUUID] = useState<string | null>();
    const [confirmPassword, setConfirmPassword] = useState<InputState>({ value: '', error: undefined });

    const PREVENT_PASSWORD_AUTOFILL_KEY = 'new-password';

    useEffect(() => {
        setLoading(true);
        const uuidString: any = code || null;
        setUUID(uuidString);
        if (uuidString) {
            PasswordService()
                .process(uuidString)
                .then(({ changePassModel, token }) => {
                    setChangePassModel(changePassModel);
                    setToken(token);
                    setLoading(false);
                })
                .catch((error: AxiosResponse<ApiErrorModel>) => {
                    setError('Återställningslänken är inte giltig. Beställ en ny på sidan login.');
                    setLoading(false);
                });
        } else {
        }
    }, [code]);

    const handlePasswordChange = (value: string): void => {
        setPassword(s => ({ ...s, value, error: VALIDATION.password(value) }));
        setConfirmPassword(s => ({ ...s, error: VALIDATION.confirmPassword(s.value, value) }));
    };

    const handleConfirmPasswordChange = (value: string): void => {
        setConfirmPassword(s => ({ ...s, value, error: VALIDATION.confirmPassword(value, password.value) }));
        setPassword(s => ({ ...s, error: VALIDATION.password(s.value) }));
    };

    const handleSubmitNewPassword = () => {
        setSubmitting(true);

        let hasErrors = false;

        if (VALIDATION.password(password.value)) {
            setPassword(s => ({ ...s, error: VALIDATION.password(s.value) }));
            hasErrors = true;
        }

        if (VALIDATION.confirmPassword(confirmPassword.value, password.value)) {
            setConfirmPassword(s => ({ ...s, error: VALIDATION.confirmPassword(s.value, password.value) }));
            hasErrors = true;
        }

        if (!hasErrors && token && uuid && changePassModel && password.value && confirmPassword.value) {
            const newChangePassModel: UserModelChangePassword = {
                ...changePassModel,
                newPassword: password.value.length ? password.value : undefined,
                newPasswordRepeated: confirmPassword.value.length ? confirmPassword.value : undefined,
                uuid: uuid,
            };

            PasswordService()
                .changePassword({ changePassModel: newChangePassModel, token })
                .then(() => {
                    setSubmitted(true);
                    setSubmitting(false);
                })
                .catch(() => {
                    setError('Återställningslänken är inte giltig. Beställ en ny på sidan login.');
                    setSubmitting(false);
                });
        }
    };

    const handleRedirectToLogin = (): void => history.push(LOGIN);

    return (
        <div className={styled.LoginBackground}>
            {loading && <LoadingSpinner message={'Validerar återställningslänk'} />}

            {submitting && !loading && <LoadingSpinner message={'Sparar nytt lösenord'} />}

            <div className={styled.Login}>
                {!error && changePassModel && !submitted && (
                    <div>
                        <div className={styled.Header}>
                            <p>Hej {changePassModel.fullName}.</p>
                        </div>
                        <div className={styled.SubHeader}>
                            <p>Skriv ditt nya lösenord:</p>
                        </div>

                        <div className={styled.InputGroup}>
                            <Input
                                type="password"
                                className={styled.Input}
                                value={password.value}
                                onChange={handlePasswordChange}
                                label={t('inputFieldPassword')}
                                error={password.error}
                                subLabel={t('inputFieldErrorPassword')}
                                autoComplete={PREVENT_PASSWORD_AUTOFILL_KEY}
                            />
                            <Input
                                type="password"
                                className={styled.Input}
                                value={confirmPassword.value}
                                onChange={handleConfirmPasswordChange}
                                label={t('inputFieldConfirmPassword')}
                                error={confirmPassword.error}
                                autoComplete={PREVENT_PASSWORD_AUTOFILL_KEY}
                            />
                        </div>
                        <div className={styled.ButtonGroup}>
                            <Button
                                variant="Primary"
                                onClick={handleSubmitNewPassword}
                                className={styled.LoginBtn}
                                disabled={password.error !== undefined || confirmPassword.error !== undefined}
                            >
                                {!loading ? t('buttonSaveNewPassword') : 'Sparar...'}
                            </Button>
                            <Button variant="Secondary" onClick={handleRedirectToLogin} className={styled.RedirectBtn}>
                                {'Till Login (länken makuleras)'}
                            </Button>
                        </div>
                    </div>
                )}
                {error && (
                    <div className={styled.Header}>
                        <p>{error}</p>
                        <div className={styled.ButtonGroup}>
                            <Button variant="Primary" data-variant="error" className={styled.ToLoginBtn} onClick={handleRedirectToLogin}>
                                {'Till Login'}
                            </Button>
                        </div>
                    </div>
                )}
                {changePassModel && !error && submitted && (
                    <div className={styled.Header}>
                        <p>Ditt lösenord är ändrat</p>
                        <div className={styled.ButtonGroup}>
                            <Button variant="Primary" className={styled.ToLoginBtn} onClick={handleRedirectToLogin}>
                                {'Till Login'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResetPassword;
