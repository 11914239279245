import { AxiosResponse } from 'axios';
import { getApi } from '../config/axios.config';
import useStorage from '../hooks/useStorage';
import UserModelChangePassword from '../models/UserModelChangePassword';

export interface ChangePasswordProps {
    changePassModel: UserModelChangePassword;
    token: string;
}

const PasswordService = () => {
    const axiosInstance = getApi();
    const { clearStorage } = useStorage();

    function postForgotPasswordEmail(username: string): Promise<AxiosResponse> {
        return axiosInstance.put(`/forgotpass?username=${username}`);
    }

    function process(code: string): Promise<ChangePasswordProps> {
        clearStorage();
        return axiosInstance.get<UserModelChangePassword>(`/forgotpass/process?uuid=${code}`).then(response => ({
            changePassModel: response.data,
            token: response.headers.authorization,
        }));
    }

    function changePassword({ changePassModel, token }: ChangePasswordProps): Promise<AxiosResponse> {
        return axiosInstance.post<UserModelChangePassword>(`/password/change`, changePassModel, { headers: { Authorization: token } });
    }

    return {
        postForgotPasswordEmail,
        process,
        changePassword,
    };
};

export default PasswordService;
